import React from 'react';
import PropTypes from 'prop-types';

import { generateUniqueId } from 'utilities';
import { AcademyInfoCard } from 'components/academyInfoCard';

const AcademiesHomePage = ({ academies, title }) => {
  return (
    <section className="home-academies">
      <div className="page__frame">
        <h2 className="home-academies__title">{title}</h2>
        {academies && academies.length > 0 && (
          <div className="home-academies__wrapper cards-row">
            {academies.map((academy) => (
              <React.Fragment key={generateUniqueId()}>
                <div className="cards-row__column">
                  <AcademyInfoCard
                    admissionStartDate={
                      academy.frontmatter.info.admissionStartDate
                    }
                    applicationDeadline={
                      academy.frontmatter.info.applicationDeadline
                    }
                    admissionEndDate={academy.frontmatter.info.admissionEndDate}
                    title={academy.frontmatter.title}
                    slug={academy.fields.slug}
                    startDate={academy.frontmatter.info.startDate}
                    type={academy.frontmatter.info.type}
                    endDate={academy.frontmatter.info.endDate}
                    location={academy.frontmatter.info.location}
                  />
                </div>
              </React.Fragment>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

AcademiesHomePage.propTypes = {
  academies: PropTypes.arrayOf(PropTypes.shape()),
  title: PropTypes.string.isRequired,
};

export { AcademiesHomePage };
