import React from 'react';
import PropTypes from 'prop-types';

import { generateUniqueId } from 'utilities';

const AcademiesStatistics = ({ statistics }) =>
  statistics &&
  statistics.length > 0 && (
    <div className="academies-statistics">
      <div className="page__frame">
        <div className="academies-statistics__wrapper">
          {statistics.map((info) => (
            <div
              className="academies-statistics__tile"
              key={generateUniqueId()}
            >
              <div className="academies-statistics__element">
                <div className="academies-statistics__number">
                  {info.number}
                </div>
                <div className="academies-statistics__label">{info.label}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

AcademiesStatistics.propTypes = {
  statistics: PropTypes.arrayOf(
    PropTypes.shape({
      number: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
};

export { AcademiesStatistics };
