import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { generateUniqueId } from 'utilities';
import CircledArrow from 'icons/circled-arrow.svg';

const FeaturedAcademies = ({ academies }) => {
  const formatTitle = (title) => {
    if (title.split('for').length > 1) {
      return `${title.split('for')[0]} <br>for${title.split('for')[1]}`;
    }
    return title;
  };

  return (
    academies &&
    academies.length > 0 && (
      <div className="featured-academies">
        <div className="featured-academies__frame">
          <div className="featured-academies__row">
            {academies.map((academy) => (
              <div
                className="featured-academies__column"
                key={generateUniqueId()}
              >
                <div className="featured-academies__tile">
                  <h2
                    className="featured-academies__title"
                    dangerouslySetInnerHTML={{
                      __html: formatTitle(academy.title),
                    }}
                  />
                  <p className="featured-academies__desc">
                    {academy.description}
                  </p>
                  <Link
                    className="featured-academies__link btn-link btn-link--circled-arrow"
                    to={academy.url}
                  >
                    View Courses
                    <CircledArrow />
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  );
};

FeaturedAcademies.propTypes = {
  academies: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ),
};

export { FeaturedAcademies };
