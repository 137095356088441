import React from 'react';
import PropTypes from 'prop-types';

import { CMSLink } from 'components/cmsLink';
import { Image } from 'components/conditionalImage';

const BookPromo = ({
  title,
  description,
  image,
  imageText,
  link,
  linkText,
}) => {
  return (
    <div className="home-book-promo">
      <div className="home-book-promo__content-wrapper">
        <h3 className="home-book-promo__title">{title}</h3>
        <p className="home-book-promo__description">{description}</p>
        {link && linkText && (
          <CMSLink linkUrl={link} className="home-book-promo__btn btn">
            {linkText}
          </CMSLink>
        )}
      </div>
      <figure className="home-book-promo__figure">
        <Image
          image={image}
          imageText={imageText}
          className="home-book-promo__image"
        />
      </figure>
    </div>
  );
};

BookPromo.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageText: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object]),
  link: PropTypes.string,
  linkText: PropTypes.string,
};

export { BookPromo };
