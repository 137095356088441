import React from 'react';
import PropTypes from 'prop-types';

import { CMSLink } from 'components/cmsLink';
import { Image } from 'components/conditionalImage';

const AuthorPromo = ({ title, description, image, link, linkText }) => (
  <div className="home-author-promo">
    <figure className="home-author-promo__figure">
      <Image
        image={image}
        imageText={title}
        className="home-author-promo__image"
      />
    </figure>
    <div className="home-author-promo__content">
      <h3 className="home-author-promo__name">{title}</h3>
      <p className="home-author-promo__description">
        {description}
        {link && linkText && (
          <CMSLink linkUrl={link} className="home-author-promo__link btn-link">
            {linkText}
          </CMSLink>
        )}
      </p>
    </div>
  </div>
);

AuthorPromo.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object]),
  link: PropTypes.string,
  linkText: PropTypes.string,
};

export { AuthorPromo };
