import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import './scss/styles.scss';
import { GlobalStateContext } from 'containers/GlobalContextProvider';
import { academiesPagesEnglish, createCookie, contactInfo } from 'utilities';
import { Layout } from 'layout';
import { HeroBannerInfo } from 'components/heroBanner';
import { VideoGallery } from 'components/videoGallery';
import { AcademyContactsWithAddress } from 'components/academyContacts';
import {
  AcademiesHomePage,
  AcademiesStatistics,
  FeaturedAcademies,
  AuthorPromo,
  BookPromo,
} from './components';
import { Footer } from '../../components/pageMasterLayout/Footer';

const frontPage = ({ data }) => {
  const { markdownRemark } = data;
  const frontmatter = markdownRemark.frontmatter;
  const languageContext = useContext(GlobalStateContext);
  const translation = languageContext.dictionary.translations;

  useEffect(() => {
    languageContext.setLanguage({ id: 'en' });
    createCookie('language', 'en', 30);
  }, []);

  const academies = academiesPagesEnglish();
  const contactsAndLocations = contactInfo()[0];
  const academyStudentsContacts = contactsAndLocations.frontmatter.contacts.find(
    (contact) => contact.academy === 'Students'
  );

  return (
    <Layout
      darkTheme
      metaTitle={
        markdownRemark.frontmatter.metaInformation.metaTitle ||
        markdownRemark.frontmatter.title
      }
      metaDescription={
        markdownRemark.frontmatter.metaInformation.metaDescription || null
      }
      metaKeywords={
        markdownRemark.frontmatter.metaInformation.metaKeywords || null
      }
      metaImage={
        markdownRemark.frontmatter.metaInformation.metaImage
          ? markdownRemark.frontmatter.metaInformation.metaImage.children[0]
              .fixed.src
          : null
      }
    >
      <HeroBannerInfo
        backgroundImage={
          frontmatter.heroBg && frontmatter.heroBg.children[0].fluid.src
        }
        className="home-hero-banner"
        title={frontmatter.title}
        description={frontmatter.shortDescription}
      />
      <FeaturedAcademies
        academies={markdownRemark.frontmatter.featuredAcademies}
      />
      <AcademiesStatistics statistics={markdownRemark.frontmatter.statistics} />
      <div className="page__frame">
        <div className="home-book-section">
          <BookPromo
            description={frontmatter.homeBookPromo.description}
            title={frontmatter.homeBookPromo.title}
            link={frontmatter.homeBookPromo.linkUrl}
            imageText={frontmatter.homeBookPromo.imageText}
            image={
              frontmatter.homeBookPromo.image &&
              frontmatter.homeBookPromo.image.children[0].fluid
            }
            linkText={frontmatter.homeBookPromo.linkText}
          />
          <AuthorPromo
            description={frontmatter.homeAuthorPromo.description}
            title={frontmatter.homeAuthorPromo.title}
            link={frontmatter.homeAuthorPromo.linkUrl}
            image={
              frontmatter.homeAuthorPromo.image &&
              frontmatter.homeAuthorPromo.image.children[0].fluid
            }
            linkText={frontmatter.homeAuthorPromo.linkText}
          />
        </div>
      </div>
      <VideoGallery videos={markdownRemark.frontmatter.videoGallery} />
      <AcademiesHomePage
        title={frontmatter.academiesTitle}
        academies={academies}
      />
      <AcademyContactsWithAddress
        className="academy-contacts--component-wrapper academy-contacts__no-background"
        email={academyStudentsContacts.email}
        locations={contactsAndLocations.frontmatter.locations}
        trademark={translation['contacts.trademark']}
      />
      <Footer />
    </Layout>
  );
};

frontPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape(),
  }),
};

export const frontPageQuery = graphql`
  query frontPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        metaInformation {
          metaTitle
          metaDescription
          metaKeywords
          metaImage {
            children {
              ... on ImageSharp {
                fixed(width: 1080, height: 1080) {
                  src
                }
              }
            }
          }
        }
        title
        shortDescription
        heroBg {
          children {
            ... on ImageSharp {
              fluid(maxWidth: 1700, maxHeight: 1100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        academiesTitle
        thoughtLeaders {
          title
          leaderName
          shortDescription
          linkText
          linkUrl
          videoId
        }
        videoGallery {
          title
          thumbnail {
            children {
              ... on ImageSharp {
                fluid(maxWidth: 470, maxHeight: 270) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          videoID
        }
        statistics {
          number
          label
        }
        homeBookPromo {
          title
          description
          linkUrl
          linkText
          image {
            children {
              ... on ImageSharp {
                fluid(maxWidth: 502, maxHeight: 684) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          imageDescription
        }
        homeAuthorPromo {
          title
          description
          linkUrl
          linkText
          image {
            children {
              ... on ImageSharp {
                fluid(maxWidth: 200, maxHeight: 200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        featuredAcademies {
          title
          description
          url
        }
      }
    }
  }
`;

export default frontPage;
